<template>
  <div class="contact container-fluid d-flex flex-column">
    <div class=" my-4 text-start container">
      <h1 class="">Contact</h1>
      <p>
        Please reach out if you have any questions or would like to schedule
        a time to meet for a quote or consultation.
      </p>
      <p>
        I'm looking forward to getting in touch with you!
      </p>
    </div>
    <div class="container form text-start">
      <div v-if="sent" class="alert alert-success" role="alert">
        Thank you for reaching out! I will get back with you as soon as I can!
      </div>
      <form method="get" enctype="text/plain" class="p-3">
        <div class="mb-3">
          <label for="emailAddress" class="form-label">Email Address</label>
          <input type="email" required autocomplete="email" autofocus class="form-control" emterkeyhint="next" id="emailAddress" placeholder="name@example.com">
        </div>
        <div class="mb-3">
          <label for="emailSubject" class="form-label">Subject</label>
          <input v-model="subject" required class="form-control" enterkeyhint="next" id="emailSubject" type="text" aria-label="default input example">
        </div>
        <div class="mb-3">
          <label for="emailMessage" class="form-label">Message</label>
          <textarea v-model="message" required class="form-control" enterkeyhint="done" id="emailMessage" rows="3"></textarea>
        </div>
        <a :href="query" v-on:click="EmailSent"><button type="button" value="send" class="btn btn-lg send btn-primary float-start mb-3">Send</button></a>
      </form>
    </div>
  </div>
</template>

<script>
let vue = {};

export default {
  name: "Contact",
  data() {
    return {
      email: 'dmdrapp@gmail.com',
      subject: '',
      message: '',
      sent: false  
    }
  },
  methods: {
    EmailSent() {
      vue.sent = true;
    }
  },
  created() {
    vue = this;
  },
  computed: {
    query() {
      return `mailto:${this.email}?subject=${this.subject}&body=${this.message}`
    }
  }
};
</script>


<style scoped>
.form {
  max-width: 500px;
}
.send {
  background: #023E8A !important;
  border: #023E8A !important;
}
</style>