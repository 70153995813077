<template>
  <div class="about container d-flex flex-column">

    <div class="my-4">
      <img style="width: 50%; max-width: 350px; filter:drop-shadow(4px 4px 3px black) " src="@/assets/profile.png" alt="">
    </div>
    <div class="text-start">
      <h1 class="">About Me</h1>
      <p>
        Hello! I am Derek Drapp, and I have been working in 
        web development for over 7 years and software development
        for 3. I have a degree in Computer Programming, and have 
        taken courses in several coding languages.
      </p>
      <p>
        My passion started when I wanted to build a webpage for an 
        idea I had. After making a blog and adding several posts, I
        realized that I loved building the site more than maintaining
        it. From there, I continued to dive into the world of web
        development, and continue to build on my skillset.
      </p>
      <p>
        As I've grown in my knowledge, I have worked with several 
        businesses and built many websites that promote their brand
        they way they want to be seen. They see improved contacts,
        better customer interaction, and overall sales!
      </p>
      <p>
        If you are interested in using me for your next big project,
        you can <router-link to="/Contact">contact me</router-link>
        and I will respond as quickly as I can!
      </p>
      <p>
        Looking forward to our next project together!
      </p>
      <h4 style="font-family: rock salt">
        - Derek D.
      </h4>
    </div>
    <router-link class="btn btn-lg btn-primary hire align-self-center" to="/Contact">Hire Me</router-link>
  </div>
</template>

<style>

</style>